/* eslint-disable */
import * as React from "react";
import { FC, SVGProps } from "react";

const BinIcon: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => (
    <svg height={17.5} viewBox="0 0 17.5 17.5" width={17.5} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h17.5v17.5H0Z" data-name="Path 3868" fill="none" />
        <path
            d="M12.202 6.854v7.778H5.979V6.854h6.222m-1.166-4.667H7.146l-.778.778H3.646V4.52h10.889V2.965h-2.722Zm2.722 3.111H4.424v9.333a1.56 1.56 0 0 0 1.555 1.556h6.222a1.56 1.56 0 0 0 1.556-1.556Z"
            data-name="Path 3869"
            fill="#646464"
        />
    </svg>
);

export default BinIcon;
