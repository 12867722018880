import * as React from "react";
import { SVGProps } from "react";

const PlusQuantityIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h24v24H0Z" data-name="Path 3861" fill="none" />
        <path
            d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4Zm-1-5a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8Z"
            data-name="Path 3862"
            fill="#646464"
        />
    </svg>
);

export default PlusQuantityIcon;
