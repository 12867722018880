import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../../../icons/infoIcon';
import { GlobalState } from '../../../../../state/@types/redux.interface';
import BinIcon from '../../../../../icons/BinIcon';
import { clearCurrentRestaurantItemsAction } from '../../../../../state/cart.reducer';
import { getQueryParam } from '../../../../../utils/queryParams';
import ExpandIcon from '../../../../../icons/ExpandIcon';
import AppButton from '../../../../common/AppButton';
import ItemInBillPanel from './ItemInBillPanel';

const BillPanelWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.06);
    margin-bottom: 100px;
    padding: 20px;
`;
const BillPanelRestaurantNameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const BillPanelRestaurantNameText = styled.div`
    color: #646464;
    font-size: 12px;
`;
const BillPanelRestaurantNameButtons = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 36.5px;
    & > * {
        cursor: pointer;
    }
`;
const Separator = styled.div`
    background-color: #CFCFCF;
    height: 1px;
    margin: 10px 0;
    width: 100%;
`;
const BillPanelAverageWaitingTime = styled.div`
    display: flex;
    justify-content: space-between;
`;
const BillPanelAverageWaitingTimeText = styled.div`
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
`;
const BillPanelRemarksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const BillPanelRemarksHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const BillPanelRemarksTextWrapper = styled.div`
    align-items: flex-start;
    display: flex;
`;
const BillPanelRemarksText = styled.div`
    color: #646464;
    font-size: 12px;
    font-weight: 500;
`;
const BillPanelUserRemarksText = styled.div`
    color: #646464;
    font-size: 12px;
    font-weight: 300;
`;
const BillPanelRemarksExpandIconWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
const RemarksPanelWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
`;
const RemarksTextArea = styled.textarea`
    border: 1px solid #CFCFCF;
    font-size: 12px;
    height: 100px;
    margin-bottom: 10px;
    resize: none;
    width: 100%;
`;
const SaveRemarksButton = styled.div`
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 500;
    padding: 9px 0;
    text-align: center;
    width: 120px;
`;

interface BillPanelInterface {
    remarks: string;
    setRemarks: React.Dispatch<React.SetStateAction<string>>;
}

const BillPanel: FC<BillPanelInterface> = ({ remarks, setRemarks }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const cartSelector = useSelector((state: GlobalState) => state.cart);
	const menuSelector = useSelector((state: GlobalState) => state.menu);

	const [remarksPanelExpanded, setRemarksPanelExpanded] = useState<boolean>(false);
	const [textAreaInput, setTextAreaInput] = useState<string>('');

	const { ordersInCurrentRestaurant } = cartSelector;
	const { menu } = menuSelector;

	const tableId = getQueryParam('tableId');
	const restaurantId = getQueryParam('restaurantId');

	const cleanRestaurantCart = () => {
		dispatch(clearCurrentRestaurantItemsAction(menu?.restaurant?.id));
	};

	// useEffect(() => {
	// 	if (ordersInCurrentRestaurant.length === 0) navigate(`/table/menu/details/?tableId=${tableId}&restaurantId=${restaurantId}`);
	// }, [ordersInCurrentRestaurant]);

	const saveRemarks = () => {
		setRemarks(textAreaInput);
		setRemarksPanelExpanded(false);
		setTextAreaInput('');
	};

	return (
		<>
			{ordersInCurrentRestaurant?.length !== 0
		&& (
			<BillPanelWrapper>
				<BillPanelRestaurantNameWrapper>
					<BillPanelRestaurantNameText>
						{t('cart.orderFrom')}
                        &nbsp;
						<b>{menu?.restaurant?.name}</b>
					</BillPanelRestaurantNameText>
					<BillPanelRestaurantNameButtons>
						<InfoIcon fill="#646464" />
						<BinIcon onClick={cleanRestaurantCart} />
					</BillPanelRestaurantNameButtons>
				</BillPanelRestaurantNameWrapper>
				<Separator />
				{ordersInCurrentRestaurant.map((itemInOrder) => (
					<ItemInBillPanel
						id={itemInOrder.id}
						item={itemInOrder.display.item}
						itemInOrder={itemInOrder}
						pickedModifiers={itemInOrder.display.pickedModifiers}
						quantity={itemInOrder.quantity}
					/>
				))}
				<Separator />
				<BillPanelAverageWaitingTime>
					<BillPanelRestaurantNameText>
						{t('cart.averagePrepareTime')}
					</BillPanelRestaurantNameText>
					<BillPanelAverageWaitingTimeText>
						{`${menu?.restaurant?.averageOrderPreparationTime ?? 20} min`}
					</BillPanelAverageWaitingTimeText>
				</BillPanelAverageWaitingTime>
				<Separator />
				<BillPanelRemarksWrapper>
					<BillPanelRemarksHeader>
						<BillPanelRemarksTextWrapper>
							<BillPanelRemarksText>
								{t('cart.remarks')}
								:&nbsp;
							</BillPanelRemarksText>
							<BillPanelUserRemarksText>
								{remarks || t('cart.noneRemarks')}
							</BillPanelUserRemarksText>
						</BillPanelRemarksTextWrapper>
						<BillPanelRemarksExpandIconWrapper onClick={() => setRemarksPanelExpanded(!remarksPanelExpanded)}>
							<BillPanelRemarksText>
								{t('common.write')}
							</BillPanelRemarksText>
							<ExpandIcon
								height={9.2625}
								width={15}
							/>
						</BillPanelRemarksExpandIconWrapper>
					</BillPanelRemarksHeader>
					{remarksPanelExpanded && (
						<RemarksPanelWrapper>
							<RemarksTextArea
								maxLength={255}
								onChange={(e) => setTextAreaInput(e.target.value)}
								placeholder={t('cart.writeRemarks')}
								value={textAreaInput}
							/>
							<SaveRemarksButton onClick={saveRemarks}>{t('common.save')}</SaveRemarksButton>
						</RemarksPanelWrapper>
					)}
				</BillPanelRemarksWrapper>
			</BillPanelWrapper>
		)}
		</>
	);
};
export default BillPanel;
