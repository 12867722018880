import React, { FC } from 'react';
import styled from 'styled-components';
import { ItemInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/item.interface';
import { useDispatch } from 'react-redux';
import PlusQuantityIcon from '../../../../../icons/PlusQuantityIcon';
import MinusQuantityIcon from '../../../../../icons/MinusQuantityIcon';
import { removeItemFromCartAction, setItemQuantityAction } from '../../../../../state/cart.reducer';
import { ModifierInOrderInterface } from '../../../../../state/@types/cart/modifierInOrder.interface';
import { calculateCostInRestaurantUtil } from '../../../../../utils/calculateCostInRestaurantUtil';
import { ItemInOrderInterface } from '../../../../../state/@types/cart/itemInOrder.interface';
import { ItemInCartInterface } from '../../../../../state/@types/cart/itemInCart.interface';
import { showPriceUtil } from '../../../../../utils/showPriceUtil';
import { translateTitle } from '../../../../../utils/translateTitle';

const ItemInBillPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`;
const ItemInBillPanelMainRowWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
`;
const ItemInBillModifiers = styled.div`
    color: #646464;
    font-size: 12px;
    padding-left: 20px;
`;
const ItemInBillText = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;
const ItemInBillPriceText = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: right;
    white-space: nowrap;
`;
const ItemInBillButtons = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    width: 34px;
`;

interface ItemInBillPanelInterface {
    quantity: number;
    item: ItemInterface;
    id: string;
    pickedModifiers: ModifierInOrderInterface[];
    itemInOrder: ItemInCartInterface;
}

const ItemInBillPanel: FC<ItemInBillPanelInterface> = ({
	id, quantity, item, pickedModifiers, itemInOrder,
}) => {
	const dispatch = useDispatch();
	const setItemQuantity = (newQuantity) => {
		if (newQuantity > 0) {
			dispatch(setItemQuantityAction(id, newQuantity));
		} else {
			dispatch(removeItemFromCartAction(id));
		}
	};

	return (
		<ItemInBillPanelWrapper>
			<ItemInBillPanelMainRowWrapper>
				<ItemInBillText>
					{`${quantity} x ${translateTitle(item.title)}`}
				</ItemInBillText>
				<ItemInBillButtons>
					<MinusQuantityIcon onClick={() => setItemQuantity(quantity - 1)} />
				</ItemInBillButtons>
				<ItemInBillPriceText>
					{`${showPriceUtil(calculateCostInRestaurantUtil([itemInOrder]))}`}
                    &nbsp;
				</ItemInBillPriceText>
				<ItemInBillButtons>
					<PlusQuantityIcon onClick={() => setItemQuantity(quantity + 1)} />
				</ItemInBillButtons>
			</ItemInBillPanelMainRowWrapper>
			{pickedModifiers.map((modifier) => (
				<ItemInBillModifiers>
					{`${translateTitle(modifier.modifier.title)}: ${modifier.pickedModifiers.map((pickedModifier) =>
						`${pickedModifier.quantity}x${pickedModifier.pickedModifierName}`)}`}
				</ItemInBillModifiers>
			))}
		</ItemInBillPanelWrapper>
	);
};
export default ItemInBillPanel;
