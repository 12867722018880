import { PlaceOrderInterface } from '@restimo/restpay-types';
import { ItemInCartInterface } from '../state/@types/cart/itemInCart.interface';

function unifyTypesOfAddons(itemInCart: ItemInCartInterface) {
	return Object.entries(itemInCart?.formData?.addons)?.map((e) => {
		if (typeof e[1] === 'string') { // SINGLE CHOICE
			return { [e[0]]: { [e[1]]: { quantity: 1 } } };
		}
		if (Array.isArray(e[1])) { // MULTIPLE CHOICE WITHOUT COUNTER
			return {
				[e[0]]: e[1].reduce((acc, cur) => ({
					...acc,
					[cur]: { quantity: 1 },
				}), {}),
			};
		}
		if (typeof e[1] === 'object' && !Array.isArray(e[1])) { // MULTIPLE CHOICE WITH COUNTER
			return {
				[e[0]]: Object.entries(e[1])
					.reduce((acc, cur) => {
						if (cur[0] !== 'quantitySummary' && cur[1].quantity > 0) {
							return {
								...acc,
								[cur[0]]: cur[1],
							};
						}

						return acc;
					}, {}),
			};
		}
	});
}

export function itemInCartToPlaceOrderDto(itemsInCart: ItemInCartInterface[]): PlaceOrderInterface[] {
	return itemsInCart.map((itemInCart) => ({
		...itemInCart.formData,
		addons: itemInCart?.formData?.addons ? unifyTypesOfAddons(itemInCart) : null,
	}));
}
