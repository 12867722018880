/* eslint-disable */
import * as React from "react";
import { FC, SVGProps } from "react";

const CardIcon: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0Z" data-name="Path 3798" fill="none" />
        <path
            d="M15 3H3a1.488 1.488 0 0 0-1.493 1.5l-.007 9A1.5 1.5 0 0 0 3 15h12a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 15 3Zm0 10.5H3V9h12ZM15 6H3V4.5h12Z"
            data-name="Path 3799"
            fill="#fff"
        />
    </svg>
);

export default CardIcon;
