/* eslint-disable */
import * as React from "react";
import { FC } from "react";

const InfoIcon: FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={14} viewBox="0 0 14 14" width={14} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.3 3.5h1.4v1.4H6.3Zm0 2.8h1.4v4.2H6.3ZM7 0a7 7 0 1 0 7 7 7 7 0 0 0-7-7Zm0 12.6A5.6 5.6 0 1 1 12.6 7 5.607 5.607 0 0 1 7 12.6Z"
            data-name="Path 3855"
            fill={props.fill || "#fff"}
        />
    </svg>
);

export default InfoIcon;
