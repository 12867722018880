import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import CardIcon from '../../../../../icons/CardIcon';
import { clearCurrentRestaurantItemsAction } from '../../../../../state/cart.reducer';
import { getQueryParam } from '../../../../../utils/queryParams';
import { GlobalState } from '../../../../../state/@types/redux.interface';
import { itemInCartToPlaceOrderDto } from '../../../../../utils/itemInCartToPlaceOrderDto';
import { placeOrder } from '../../../../../services/orderService';

const CartFotterWrapper = styled.div`
    background-color: #06C167;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    position: fixed;
    width:100%;
`;
const CartFotterLeftSide = styled.div`
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    line-height: 12px;
`;
const CardIconWrapper = styled.div`
    margin-right: 15px;
`;

const CartFotterRightSide = styled.div`
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 12px;
    text-align: right;
`;
interface CartFooterInterface {
    cartTotalCost: number;
    remarks: string;
}

const CartFooter: FC<CartFooterInterface> = ({ cartTotalCost, remarks }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const restaurantId = getQueryParam('restaurantId');
	const tableId = getQueryParam('tableId');
	const restaurantOrder = useSelector((state: GlobalState) => state.cart.ordersInCurrentRestaurant);
	const isPickup = useSelector((state: GlobalState) => state.cart.isPickup);
	const localizationId = useSelector((state: GlobalState) => state.table.table.localization.id);

	const placeOrderAction = () => {
		// dispatch(placeOrderAction(localizationId, restaurantId, tableId, itemInCartToPlaceOrderDto(restaurantOrder), remarks));
		placeOrder(localizationId, restaurantId, tableId, itemInCartToPlaceOrderDto(restaurantOrder), remarks, isPickup)
			.then(async () => {
				dispatch(clearCurrentRestaurantItemsAction(restaurantId));
				navigate(`/table/orders?tableId=${tableId}`);
			});
	};

	return (
		<CartFotterWrapper onClick={placeOrderAction}>
			<CartFotterLeftSide>
				<CardIconWrapper><CardIcon /></CardIconWrapper>
				<b>
					{t('cart.order')}
                    &nbsp;
				</b>
				{t('cart.now')}
			</CartFotterLeftSide>
			<CartFotterRightSide>
				{(cartTotalCost / 100)?.toFixed(2) || '0,00'}
				{' '}
				zł
			</CartFotterRightSide>
		</CartFotterWrapper>
	);
};
export default CartFooter;
