/* eslint-disable */
import * as React from "react";
import { FC, SVGProps } from "react";

const BillIcon: FC = (props: SVGProps<SVGSVGElement>) => (
    <svg height={21.5} viewBox="0 0 21.5 20.25" width={20.25} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0Z" data-name="Path 3831" fill="none" />
        <g data-name="Group 5880" fill={props.fill || "#fff"}>
            <path
                d="m18.75 3-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5L9.75 3l-1.5-1.5L6.75 3l-1.5-1.5v14h-3v3a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-17Zm-4.5 16.5h-9a1 1 0 0 1-1-1v-1h10Zm4-1a1 1 0 0 1-2 0v-3h-9v-11h11Z"
                data-name="Path 3832"
            />
            <path d="M9.85 6.25h6v1h-6z" data-name="Rectangle 3297" />
            <path d="M9.85 8.964h6v1h-6z" data-name="Rectangle 3299" />
        </g>
    </svg>
);

export default BillIcon;
