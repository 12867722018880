import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BillIcon from '../../../../icons/BillIcon';
import BillPanel from '../../../../components/table/menu/details/cart/BillPanel';
import CartFooter from '../../../../components/table/menu/details/cart/CartFooter';
import { GlobalState } from '../../../../state/@types/redux.interface';
import useGetIdsFromUrlAndFetch from '../../../../utils/useGetIdsFromUrlAndFetch';
import { Loading } from '../../../../components/common/loading';
import { calculateCostInRestaurantUtil } from '../../../../utils/calculateCostInRestaurantUtil';

const CartWrapper = styled.div`
    background-color: #fbfbfb;
    min-height: 100%;
    padding: 30px;
`;
const CartHeader = styled.div`
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 30px;
`;
const SemiBold = styled.div`
    font-weight: 500;
`;

const Cart: FC = () => {
	const { t } = useTranslation();
	useGetIdsFromUrlAndFetch();
	const [cartTotalCost, setCartTotalCost] = useState<number>(0);
	const [remarks, setRemarks] = useState<string>(null);

	const cartSelector = useSelector((state: GlobalState) => state.cart);
	const menuSelector = useSelector((state: GlobalState) => state.menu);
	const { fetched } = menuSelector;

	const { ordersInCurrentRestaurant } = cartSelector;

	useEffect(() => {
		setCartTotalCost(calculateCostInRestaurantUtil(ordersInCurrentRestaurant));
	}, [ordersInCurrentRestaurant]);

	return (
		<>
			{fetched ? (
				<>
					<CartWrapper>
						<CartHeader>
							<BillIcon fill="#000000" />
							&nbsp;
							{t('common.your')}
							<SemiBold>
                                &nbsp;
								{t('common.orderNoun')}
							</SemiBold>
						</CartHeader>
						<BillPanel
							remarks={remarks}
							setRemarks={setRemarks}
						/>
						<span style={{ height: 30 }} />
					</CartWrapper>
					<CartFooter
						cartTotalCost={cartTotalCost}
						remarks={remarks}
					/>
				</>
			) : <Loading />}
		</>
	);
};
export default Cart;
