import * as React from 'react';
import { FC, SVGProps } from 'react';

const ExpandIcon: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={6.175}
		viewBox="0 0 6.175 10"
		width={10}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.825 0 5 3.817 1.175 0 0 1.175l5 5 5-5Z"
			data-name="Path 3866"
			fill="#646464"
		/>
	</svg>
);

export default ExpandIcon;
