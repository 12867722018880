import * as React from "react";
import { SVGProps } from "react";

const MinusQuantityIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h24v24H0Z" data-name="Path 3863" fill="none" />
        <path
            d="M7 11v2h10v-2Zm5-9a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8Z"
            data-name="Path 3864"
            fill="#646464"
        />
    </svg>
);

export default MinusQuantityIcon;
