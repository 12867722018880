import { ItemInCartInterface } from '../state/@types/cart/itemInCart.interface';

export const calculateCostInRestaurantUtil = (ordersInCurrentRestaurant: ItemInCartInterface[]): number =>
	ordersInCurrentRestaurant.reduce((acc, cur) => {
		const itemPrice = cur?.display.item?.priceInfo?.price;
		const modifiersPrice = cur?.display.pickedModifiers
			.reduce((modAcc, modCur) => {
				modAcc += modCur.pickedModifiers
					.reduce((modItemsAcc, modItemsCur) => {
						modItemsAcc += (modItemsCur.price * modItemsCur.quantity);
						return modItemsAcc;
					}, 0);
				return modAcc;
			}, 0);
		acc += (itemPrice + modifiersPrice) * cur?.quantity;
		return acc;
	}, 0);
